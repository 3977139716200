import React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import {
  AboutSection,
  CarbonNeutralSecction,
} from "../styles/pages/carbon-neutrality";
import carlosPol from "../images/carbon/carlos-pol.png";
import climateNeutral from "../images/logos/CARBON_NEUTRAL_BLACK.png";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import { Button, Grid, Stack } from "@mui/material";
import useSizeQuery from "../hooks/useSizeQuery";

const Pledge = () => {
  const { t } = useTranslation("carbon");

  const { isMobile } = useSizeQuery();

  return (
    <>
      <Seo
        canonical="https://empresas.lapieza.io/carbon-neutrality"
        title="Comprometidos con reducir la huella de carbono digital."
        description="En LaPieza, compartimos nuestra ambición para ayudar a otros a acelerar su progreso, comenzando por el impacto de la huella de carbono digital."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <Bg.Image
          opacity={0.1}
          img="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fcarbon-neutrality%2FHeader.png?alt=media&token=39b270a1-0193-44dd-83d7-ed987de282fe"
        >
          <Stack
            maxWidth="500px"
            padding={{
              xs: "0 50px",
              sm: "0 50px",
              md: "0 0 0 110px",
              lg: "0 0 0 110px",
            }}
            height="400px"
            justifyContent="center"
          >
            <Text.SubTitle textAlign="start" color="primary.white">
              {t("heroTitle")}
            </Text.SubTitle>
            <Text.Title textAlign="start" color="primary.white">
              {t("heroSubtitle")}
            </Text.Title>
          </Stack>
        </Bg.Image>
        <Grid container>
          <Grid item lg={6} md={6} sm={12}>
            <Bg.Linear>
              <Stack
                padding={{
                  xs: "50px",
                  sm: "50px",
                  md: "50px",
                  lg: "50px 110px",
                }}
                spacing={2}
              >
                <Text.Title isH1 textAlign="start" fontSize="2.8rem">
                  {t("infoTitle")}
                </Text.Title>
                <Text.Body fontSize="1.6rem" textAlign="start">
                  {t("infoDescription")}
                </Text.Body>
              </Stack>
            </Bg.Linear>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Stack width="100%" height="47rem">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={carlosPol}
                alt="Co-fundadores de LaPieza"
              />
            </Stack>
          </Grid>
        </Grid>
        <CarbonNeutralSecction>
          <div className="carbon-neutral-section main-landing-container-column">
            <div className="info-section">
              <figure>
                <img src={climateNeutral} alt="Carbon Neutral certified" />
              </figure>
              <article className="info-section-item">
                <Stack spacing={3} mb={3}>
                  <Text.Title textAlign="start">{t("carbonTitle")}</Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("carbonDescription")}
                  </Text.Body>
                </Stack>
                <Button
                  sx={{
                    width: "170px",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    to="/contact"
                  >
                    {t("seeMore")}
                  </Link>
                </Button>
              </article>
            </div>
          </div>
        </CarbonNeutralSecction>
        <Bg.Gradient className="data-section">
          <Stack padding={isMobile ? "100px 50px" : "100px 140px"}>
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems={isMobile ? "center" : "end"}
            >
              <Stack direction="row" alignItems="end">
                <Text.Title fontSize="5rem" sx={{ mr: 1 }}>
                  126
                </Text.Title>
                <Stack justifyContent="end" mb={1}>
                  <Text.Title className="data-section-tco">tCO </Text.Title>
                </Stack>
                <Stack mb={5}>
                  <Text.Title fontSize="1.6rem">2</Text.Title>
                </Stack>
              </Stack>
              <Text.Title
                sx={{
                  mb: 1,
                  ml: 1,
                }}
              >
                {t("carbonItemTitle")}
              </Text.Title>
            </Stack>
            <Text.Body
              textAlign={isMobile ? "center" : "start"}
              fontSize="1.8rem"
            >
              {t("carbonItemDescription")}
            </Text.Body>
          </Stack>
        </Bg.Gradient>
        <AboutSection>
          <div className="main-landing-container-column">
            <Stack mt={6}>
              <Text.SubTitle>{t("aboutUsTitle")}</Text.SubTitle>
              <Text.Title>{t("aboutUsSubtitle")}</Text.Title>
            </Stack>
            <div className="climeworks-section">
              <article>
                <Stack height="100%" justifyContent="center" spacing={2}>
                  <Text.Title textAlign="start">
                    {t("aboutUsItemTitle1")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.6rem">
                    {t("aboutUsItemSubtitle1")}
                  </Text.Body>
                  <Button
                    href="#"
                    sx={{
                      width: "170px",
                    }}
                  >
                    {t("seeMore")}
                  </Button>
                </Stack>
              </article>
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fcarbon-neutrality%2FClimeworks.png?alt=media&token=3e445d5b-4425-48c2-a0ed-6a0da1c1ee1e"
                  alt="Climeworks"
                />
              </figure>
            </div>
            <div className="cooleffect-section">
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fcarbon-neutrality%2FCoolEffect.png?alt=media&token=5aa3e9f6-3858-417b-9df3-3c1640bac520"
                  alt="CoolEffect"
                />
              </figure>
              <article>
                <Stack height="100%" justifyContent="center" spacing={2}>
                  <Text.Title textAlign="start">
                    {t("aboutUsItemTitle2")}
                  </Text.Title>
                  <Stack spacing={4}>
                    <Text.Body textAlign="start" fontSize="1.6rem">
                      {t("aboutUsItemSubtitle2")}
                    </Text.Body>
                    <Text.Body textAlign="start" fontSize="1.6rem">
                      {t("aboutUsItemDescription2")}
                    </Text.Body>
                  </Stack>
                  <ul>
                    <li>
                      <Text.Body fontSize="1.6rem">
                        {t("aboutUsListItem1")}
                      </Text.Body>
                    </li>
                    <li>
                      <Text.Body fontSize="1.6rem">
                        {t("aboutUsListItem2")}
                      </Text.Body>
                    </li>
                    <li>
                      <Text.Body fontSize="1.6rem">
                        {t("aboutUsListItem3")}
                      </Text.Body>
                    </li>
                  </ul>
                  <Button
                    href=""
                    sx={{
                      width: "170px",
                    }}
                  >
                    {t("seeMore")}
                  </Button>
                </Stack>
              </article>
            </div>
          </div>
        </AboutSection>
        <Bg.Linear>
          <Grid
            container
            padding={{
              xs: "40px",
              sm: "40px",
              md: "70px 80px",
              lg: "70px 110px",
            }}
          >
            <Grid item lg={6} md={6} sm={12}>
              <Stack
                alignItems="start"
                spacing={4}
                justifyContent="center"
                height="100%"
                maxWidth="500px"
              >
                <Text.Title textAlign="start">{t("contactTitle")}</Text.Title>
                <Text.Body textAlign="start" fontSize="1.8rem">
                  {t("contactDescription")}
                </Text.Body>
                <Text.Body textAlign="start" fontSize="1.8rem">
                  {t("contactMessage")}{" "}
                  <a
                    style={{
                      color: "#EF8E02",
                      textDecoration: "underline",
                    }}
                    href="mailto:carlos@lapieza.io"
                  >
                    carlos@lapieza.io
                  </a>
                </Text.Body>
                <Text.Body fontSize="1.8rem">{t("contactMessage2")}</Text.Body>
                <Button>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    to="/contact"
                  >
                    {t("contactTeam")}
                  </Link>
                </Button>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Stack alignItems="center" justifyContent="center">
                <figure>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fcarbon-neutrality%2FquestionPiece.png?alt=media&token=a49c3bcb-2738-4fe0-85a9-aa40b39f2b0e"
                    alt="Neutralidad de carbono | LaPieza"
                  />
                </figure>
              </Stack>
            </Grid>
          </Grid>
        </Bg.Linear>
      </Layout>
    </>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
